// $color-principal: #246f29;
$color-principal: #032E26;
$color-principal-one: #decce9a3;
$color-principal-dark: #00261b;
$color-principal-light: #168038;
$color-second: #decce9a3;
$color-danger: #f90000;
$color-warning: #ffb42a;
$color-transparent: rgba(0, 0, 0, 0.07);
$color-gray: #707070;
$color-white: #ffffff;
$color-header: #ffffff;
$color-background: #ffffff;
$color-border: #dddddd;

$height-header: 70px;
$width-sidebar: 260px;

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"), url(../assets/fonts/poppins/Poppins-Regular.otf) format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"), url(../assets/fonts/poppins/Poppins-Bold.otf) format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "OpenSans-Regular";
  src: local("OpenSans-Regular"), url(../assets/fonts/open-sans/OpenSans-Regular.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "OpenSans-Bold";
  src: local("OpenSans-Bold"), url(../assets/fonts/open-sans/OpenSans-Bold.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "OpenSans-SemiBold";
  src: local("OpenSans-SemiBold"), url(../assets/fonts/open-sans/OpenSans-Semibold.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "OpenSans-Light";
  src: local("OpenSans-Light"), url(../assets/fonts/open-sans/OpenSans-Light.ttf) format("truetype");
  font-weight: normal;
}

@mixin box-shadow {
  box-shadow: 1px 0 15px 0 #00000010;
}

.shadow-custom {
  box-shadow: 1px 0 15px 0 #00000010 !important;
}

.shadow-0 {
  box-shadow: none !important;
}

// Resets
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: "Poppins-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif !important;
  margin: 0;
  padding: 0;
  font-size: 14px;
  box-sizing: border-box;
  color: #222222;
  background-color: $color-background;
  transition: all 0.3s ease-in-out;
}

ul {
  margin-bottom: 0 !important;
}

li {
  list-style: none !important;
}

a {
  text-decoration: none !important;
}

*:focus {
  outline: none;
}

::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  border: 0px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ffffff;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb {
  background-color: $color-gray;
  border-radius: 15px;
}

::-webkit-scrollbar-corner {
  display: none;
}

::-webkit-scrollbar-button {
  display: none;
}

.w-5 {
  width: 5%;
}
.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}
.w-30 {
  width: 30%;
}
.w-35 {
  width: 35%;
}
.w-40 {
  width: 40%;
}
.w-45 {
  width: 45%;
}
.w-55 {
  width: 55%;
}
.w-60 {
  width: 60%;
}
.w-65 {
  width: 65%;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80%;
}
.w-85 {
  width: 85%;
}
.w-90 {
  width: 85%;
}
.w-95 {
  width: 85%;
}

.h-5 {
  height: 5%;
}
.h-10 {
  height: 10%;
}
.h-15 {
  height: 15%;
}
.h-20 {
  height: 20%;
}
.h-30 {
  height: 30%;
}
.h-35 {
  height: 35%;
}
.h-40 {
  height: 40%;
}
.h-45 {
  height: 45%;
}
.h-55 {
  height: 55%;
}
.h-60 {
  height: 60%;
}
.h-65 {
  height: 65%;
}
.h-70 {
  height: 70%;
}
.h-80 {
  height: 80%;
}
.h-85 {
  height: 85%;
}
.h-90 {
  width: 85%;
}
.h-95 {
  width: 85%;
}

.font-50 {
  font-size: 50%;
}
.font-60 {
  font-size: 60%;
}
.font-70 {
  font-size: 70%;
}
.font-80 {
  font-size: 80%;
}
.font-90 {
  font-size: 90%;
}
.font-125 {
  font-size: 125%;
}
.font-150 {
  font-size: 150%;
}

.text-principal {
  color: $color-principal !important;
}

.bg-black {
  background-color: black !important;
}

.bg-principal {
  background-color: $color-principal !important;
}

.text-danger {
  color: $color-danger !important;
}

.bg-danger {
  background-color: $color-danger !important;
}

.bg-transp {
  background-color: $color-transparent !important;
}

.text-second {
  color: $color-second !important;
}

.text-black {
  color: black;
}

.bg-second {
  background-color: $color-second !important;
}

.text-warning {
  color: $color-warning !important;
}

.bg-warning {
  background-color: $color-warning !important;
}

.btn {
  cursor: pointer;
  border-radius: 3px;
  padding: 10px;

  &-lg {
    padding: 13px;
    font-size: 120%;
  }

  &-sm {
    padding: 8px;
    font-size: 90%;
  }

  &-principal {
    background-color: $color-principal !important;
    color: white !important;

    &.hover {
      background-color: $color-principal !important;
    }

    &.active {
      box-shadow: 0px 0px 1px 3px lighten($color-principal, 30%) !important;
    }

    &.disabled {
      background-color: darken($color-principal, 30%) !important;
    }
  }

  &-second {
    background-color: $color-second !important;
    color: white !important;

    &.hover {
      background-color: $color-second !important;
    }

    &.active {
      box-shadow: 0px 0px 1px 3px lighten($color-second, 30%) !important;
    }

    &.disabled {
      background-color: darken($color-second, 30%) !important;
    }
  }

  &-warning {
    background-color: $color-warning !important;
    color: white !important;

    &.hover {
      background-color: $color-warning !important;
    }

    &.active {
      box-shadow: 0px 0px 1px 3px lighten($color-warning, 30%) !important;
    }

    &.disabled {
      background-color: darken($color-warning, 30%) !important;
    }
  }
}

.border {
  border: 0.5px solid #aaaaaa !important;

  &-principal {
    border: 0.5px solid $color-principal !important;
  }

  &-second {
    border: 0.5px solid $color-second !important;
  }

  &-warning {
    border: 0.5px solid $color-warning !important;
  }
}

.line-height-1-5 {
  line-height: 1.5rem;
}

.line-height-2 {
  line-height: 2rem;
}

.line-height-2-5 {
  line-height: 2.5rem;
}

.line-height-3 {
  line-height: 3rem;
}

.font-weight-semibold {
  font-family: "Poppins-SemiBold" !important;
}

.font-weight-light {
  font-family: "Poppins-Light" !important;
}

.header {
  height: $height-header;
  width: 100%;
  z-index: 3;
  margin-bottom: 50px;
  position: fixed;
  background-color: $color-white;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05);
}

.header-admin {
  height: $height-header;
  width: 100%;
  z-index: 3;
}

.footer {
  background-color: #e5ffec;
  color: #00000087;
  font-size: 0.95rem;
  min-height: 40vh;
}

#breadcrumb {
  list-style: none;
  display: inline-block;
}
#breadcrumb .icon {
  font-size: 14px;
}
#breadcrumb li {
  float: left;
}
#breadcrumb li a {
  color: #fff;
  display: block;
  background: $color-principal-light;
  text-decoration: none;
  position: relative;
  height: 40px;
  line-height: 40px;
  padding: 0 10px 0 5px;
  text-align: center;
  margin-right: 23px;
}
#breadcrumb li:nth-child(even) a {
  background-color: $color-principal-light;
}
#breadcrumb li:nth-child(even) a:before {
  border-color: $color-principal-light;
  border-left-color: transparent;
}
#breadcrumb li:nth-child(even) a:after {
  border-left-color: $color-principal-light;
}
#breadcrumb li:first-child a {
  padding-left: 15px;
  -moz-border-radius: 4px 0 0 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px 0 0 4px;
}
#breadcrumb li:first-child a:before {
  border: none;
}
#breadcrumb li:last-child a {
  padding-right: 15px;
  -moz-border-radius: 0 4px 4px 0;
  -webkit-border-radius: 0;
  border-radius: 0 4px 4px 0;
}
#breadcrumb li:last-child a:after {
  border: none;
}
#breadcrumb li a:before,
#breadcrumb li a:after {
  content: "";
  position: absolute;
  top: 0;
  border: 0 solid $color-principal-light;
  border-width: 20px 10px;
  width: 0;
  height: 0;
}
#breadcrumb li a:before {
  left: -20px;
  border-left-color: transparent;
}
#breadcrumb li a:after {
  left: 100%;
  border-color: transparent;
  border-left-color: $color-principal-light;
}
#breadcrumb li a:hover {
  background-color: #1fa549;
}
#breadcrumb li a:hover:before {
  border-color: #1fa549;
  border-left-color: transparent;
}
#breadcrumb li a:hover:after {
  border-left-color: #1fa549;
}
#breadcrumb li a:active {
  background-color: #1fa549;
}
#breadcrumb li a:active:before {
  border-color: #1fa549;
  border-left-color: transparent;
}
#breadcrumb li a:active:after {
  border-left-color: #1fa549;
}

.chs {
  &-px-responsive {
    padding-left: 50px;
    padding-right: 50px;
  }

  &-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;

    &-fix {
      height: $height-header;
    }
  }

  &-nav {
    &-logo {
      height: 100%;
      width: 150px;
      margin-left: 0;
      margin-right: 0;
      z-index: 5;

      &_img {
        height: 90%;
        width: 90%;
        object-fit: contain;
      }
    }

    &-menu {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      z-index: 3;
    }

    &-submenu {
      overflow: hidden;
      height: 0px;
      position: absolute;
      top: $height-header;
      background-color: $color-white;
      float: left;
      z-index: 1;
      min-width: 180px;
      border-radius: 0 0 10px 10px;
      transition: background-color 0.3s;
    }

    &-item {
      height: 100%;
      padding: 0 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: bold;
      cursor: pointer;
      color: #034d2878;

      &:hover {
        transition: all 0.3s ease-in-out;
      }

      &.active {
        color: $color-principal;
        transition: 0.3s;
      }
    }

    &-subitem {
      height: 50px;
      padding: 0 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-weight: normal;
      text-transform: capitalize;
      cursor: pointer;

      &:hover {
        background-color: $color-transparent;
      }
    }

    &-item:hover &-submenu {
      height: auto;
      border: 0.5px solid #dfdfdf55;
      border-top: 0;
    }

    &-item &-carret {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      transition: 0.3s;
    }

    &-item:hover &-carret {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      transition: 0.3s;
    }

    &-sublink {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      color: #555;
      height: 100%;
      width: 100%;
      font-size: 0.8rem;

      &:hover {
        color: $color-principal;
      }
    }

    &-link {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      color: inherit;

      &:hover {
        color: $color-principal;
      }

      &.active {
        color: $color-principal;
      }
    }
  }

  &-dropdown {
    position: absolute;
    font-size: 90%;
    top: 80px;
    right: 10px;
    width: 200px;
    height: auto;
    z-index: 1;
    background-color: $color-white;
    visibility: hidden;
    opacity: 0;
    transition: all 0.05s ease-in-out;
    @include box-shadow;
    border-radius: 3px;
    border: 0.5px solid $color-principal-light;

    &__list {
      margin: 0;
      padding: 3px;
      font-weight: bold;
      list-style-type: none;
    }

    &__list-item {
      padding: 10px;
      color: $color-gray;
      border-bottom: 0.5px solid #dddddd;
      display: flex;
      align-items: center;
      flex-direction: row;

      &:hover {
        background-color: $color-principal-light;
        color: white !important;
      }

      &.active {
        color: $color-principal;
        border: 0px;
        background-color: #16803822;
      }
    }

    &__icon {
      width: 35px;
    }

    &__title {
      width: 165px;
    }

    &--active {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }

  &-hamburger {
    display: none;
    text-align: center;
  }

  &-bar {
    display: block;
    border-radius: 10px;
    width: 25px;
    height: 5px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: $color-second;
  }

  &-slider {
    &-item {
      width: 100%;

      &_img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }

  &-admin {
    &-sidebar {
      background-color: $color-principal-dark;
      width: $width-sidebar;
      height: 100vh;
      overflow-y: auto;
      position: relative;
      font-size: 13px;

      &__brand {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;

        &-link {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &-img {
          height: 100%;
          object-fit: contain;
        }

        &-close {
          display: none;
        }
      }

      &-item {
        height: 80px;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;

        &-top {
          height: 20px;
          width: 100%;
          background-color: $color-principal-dark;
          transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out;
        }

        &-bottom {
          height: 20px;
          width: 100%;
          background-color: $color-principal-dark;
          transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out;
        }

        &-left {
          height: 40px;
          left: 0;
          top: 20px;
          width: 20%;
          z-index: 1;
          position: absolute;
        }

        &-link {
          z-index: 3;
          color: inherit;
          padding: 0 0 0 20px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 95%;
          margin-left: 5%;
          height: 40px;
          transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out;

          &:hover {
            color: inherit;
          }
        }

        &.active {
          background-color: white;
          transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out;
        }

        &.active &-top {
          border-radius: 0px 0px 15px 0px;
        }

        &.active &-bottom {
          border-radius: 0px 15px 0px 0px;
        }

        &.active &-link {
          color: $color-principal-dark;
          border-radius: 15px 0 0 15px;
          background-color: white;
          transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out;
        }

        &.active &-left {
          background-color: $color-principal-dark;
          transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out;
        }
      }
    }

    &-navbar {
      display: flex;
      padding: 0 10px;
      align-items: center;
      justify-content: space-between;
      height: $height-header;
      width: 100%;

      &__brand {
        display: none;
      }
    }

    &-main {
      width: 100%;
      height: calc(100vh - 70px);
      overflow-y: auto;
    }
  }

  &-bien-card {
    position: relative;

    &-infos {
      height: 250px;
      width: 100%;
      position: absolute;
      color: white;
      bottom: 0;
      background-color: #246f2977;
      display: flex;
      flex-direction: column;
      opacity: 0;
      height: 0;
      transition: all 0.1s ease-in-out;
    }

    &:hover &-infos {
      height: 250px;
      opacity: 1;
      transition: all 0.1s ease-in-out;
    }
  }

  &-mailbox {
    width: 100%;
    min-height: 200px;
    display: flex;

    &-tabs {
      width: 300px;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-right: 10px;

      &-item {
        height: "50px";
        display: flex;
        align-items: center;
        width: 100%;
        background-color: #16803812;
        padding: 0px 10px;
        border-radius: 10px;
        margin-top: 10px;
        font-size: 13px;
        cursor: pointer;

        &:hover {
          background-color: #16803832;
        }

        &.active {
          background-color: #168038;
          color: white;
        }

        &__avatar {
          width: 40px;
          height: 40px;
          font-size: 20px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: $color-principal-dark;
          color: white;
          overflow: hidden;
        }

        &__content {
          width: calc(100% - 50px);
          height: 100%;
          padding: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }

    &-content {
      width: calc(100% - 300px);
      height: 100%;
      background-color: #efefef;
      border-radius: 3px;
      padding: 10px;
      border-radius: 10px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .footer {
    padding: 30px 10px 10px 10px;
  }

  .header {
    margin-bottom: 50px;
  }

  .chs {
    &-px-responsive {
      padding-left: 10px;
      padding-right: 10px;
    }

    &-navbar {
      justify-content: space-between;
    }

    &-nav {
      &-logo {
        margin: 0;
        width: 100px;
      }

      &-menu {
        z-index: 5;
        background-color: $color-principal;
        overflow: hidden;
        justify-content: flex-start;
        width: 100%;
        overflow-y: scroll;
        text-align: center;
        transition: all 0.3s ease-in-out;
        box-shadow: 0px 5px 5px 0px #55555510 !important;

        &.active {
          height: 60vh;
          overflow: scroll;
          transition: 0.1s;
        }
      }

      &-submenu {
        display: none;
        position: unset;
        height: auto;
        width: 100%;
        background-color: $color-white;
        border-radius: 0;
        padding-left: 10px;
      }

      &-item {
        padding: 0;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: flex-start;

        &:hover {
          background-color: $color-transparent;
          transition: 0.3s;
        }

        &.active {
          color: $color-white;
          background-color: $color-transparent;
          transition: 0.3s;
        }
      }

      &-item:hover &-submenu {
        display: flex;
        flex-direction: column;
        transition: 0.3s;
      }

      &-item &-carret {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        transition: 0.3s;
      }

      &-link {
        height: auto;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:hover {
          color: white;
        }
      }
    }

    &-hamburger {
      display: block;
      cursor: pointer;
      transition: 0.3s;
    }

    &-hamburger.active {
      transform: rotate(180deg);
      transition: 0.3s;
    }

    &-slider {
    }

    &-admin {
      &-sidebar {
        position: fixed;
        z-index: 3;
        transform: translateX(-260px);
        transition: all 0.3s ease-in-out;

        &__brand {
          &-close {
            display: block;
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            color: $color-principal-light;
          }
        }

        &.active {
          transform: translate(0);
          transition: all 0.3s ease-in-out;
        }
      }

      &-navbar {
        display: flex;
        align-items: center;
        padding: 10px;

        &__brand {
          display: flex;
          color: $color-principal-light;
        }
      }

      &-main {
      }
    }

    &-mailbox {
      overflow: auto;
    }
  }
}
